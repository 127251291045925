.wrapper{
  color: #fff;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 80px 0;
  @media (max-width: 640px) {
    padding: 40px 20px;
  }
  .logotype{
    display: flex;
    gap: 20px;
    align-items: center;
    img{
      height: 90px;
    }
    .logoText {
      display: flex;
      flex-direction: column;
      font-size: 22px;
      span{
        font-weight: 600;
        font-size: 26px;
      }
    }
  }
  .text{
    text-align: center;
    font-size: 18px;
    margin-top: 20px;
  }
}