.wrapper {
  color: #fff;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 80px 0;
}
@media (max-width: 640px) {
  .wrapper {
    padding: 40px 20px;
  }
}
.wrapper .logotype {
  display: flex;
  gap: 20px;
  align-items: center;
}
.wrapper .logotype img {
  height: 90px;
}
.wrapper .logotype .logoText {
  display: flex;
  flex-direction: column;
  font-size: 22px;
}
.wrapper .logotype .logoText span {
  font-weight: 600;
  font-size: 26px;
}
.wrapper .text {
  text-align: center;
  font-size: 18px;
  margin-top: 20px;
}

