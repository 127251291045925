.text {
  color: #283161;
  font-weight: 400;
  font-size: 18px;
  margin: 10px 0;
}
.text a {
  text-decoration: underline;
  color: #6f44fa;
  font-weight: 500;
}

