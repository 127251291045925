.wrapper {
  overflow-x: hidden;
  position: relative;
  padding-bottom: 60px;
  background-image: linear-gradient(29deg, #5734ff, #a05ef0 100%);
  min-height: 100vh;


  .countryCards{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    @media (max-width: 640px) {
      padding: 0 10px;
    }
  }
}