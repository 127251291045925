.wrapper {
  overflow-x: hidden;
  position: relative;
  padding-bottom: 60px;

  .arc {
    border-bottom-left-radius: 50% 30%;
    border-bottom-right-radius: 50% 30%;
    background-image: linear-gradient(29deg, #5734ff, #a05ef0 100%);
    width: 110%;
    margin-left: -5%;
    height: 500px;
    position: absolute;
    z-index: -1;
    @media (max-width: 640px) {
      height: 800px;
      border-bottom-left-radius: 50% 15%;
      border-bottom-right-radius: 50% 15%;
    }
  }
}