.item {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-start;
  margin-top: 10px;
  .check{
    min-width: 15px;
    min-height: 15px;
    background: #6f44fa;
    border-radius: 100%;
    margin-top: 5px;
  }
  .title{
    color: #283161;
    font-size: 16px;
    span{
      font-weight: 600;
    }
  }
}