@tailwind base;
@tailwind components;
@tailwind utilities;

/**
 * @license
 * MyFonts Webfont Build ID 3867246, 2020-12-16T11:57:38-0500
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: Lufga Light by Adam Ladd
 * URL: https://www.myfonts.com/products/light-lufga-244995
 * Webfont: Lufga Regular by Adam Ladd
 * URL: https://www.myfonts.com/products/regular-lufga-245017
 * Webfont: Lufga Medium by Adam Ladd
 * URL: https://www.myfonts.com/products/medium-lufga-245035
 * Webfont: Lufga Bold by Adam Ladd
 * URL: https://www.myfonts.com/products/bold-lufga-245077
 * Webfont: Lufga Extra Bold by Adam Ladd
 * URL: https://www.myfonts.com/products/extra-bold-lufga-245094
 * Copyright: Copyright © 2023 Monotype Imaging Inc. All rights reserved.
 *
 * © 2023 MyFonts Inc. */

@font-face {
    font-family: Lufga;
    font-style: normal;
    font-weight: 300;
    src: url('/public/fonts/Lufga-Light.woff2') format('woff2'), url('/public/fonts/Lufga-Light.woff') format('woff');
}

@font-face {
    font-family: Lufga;
    font-style: normal;
    font-weight: 400;
    src: url('/public/fonts/Lufga-Regular.woff2') format('woff2'), url('/public/fonts/Lufga-Regular.woff') format('woff');
}

@font-face {
    font-family: Lufga;
    font-style: normal;
    font-weight: 500;
    src: url('/public/fonts/Lufga-Medium.woff2') format('woff2'), url('/public/fonts/Lufga-Medium.woff') format('woff');
}

@font-face {
    font-family: Lufga;
    font-style: normal;
    font-weight: 700;
    src: url('/public/fonts/Lufga-Bold.woff2') format('woff2'), url('/public/fonts/Lufga-Bold.woff') format('woff');
}

@font-face {
    font-family: Lufga;
    font-style: normal;
    font-weight: 800;
    src: url('/public/fonts/Lufga-ExtraBold.woff2') format('woff2'), url('/public/fonts/Lufga-ExtraBold.woff') format('woff');
}

body{
    font-family: Lufga;
}