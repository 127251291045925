.wrapper {
  background: #fff;
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}
@media (max-width: 640px) {
  .wrapper {
    margin: 0 10px;
  }
}

