.wrapper {
  background: #fff;
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  width: 400px;
  max-width: 100%;
  display: flex;
  gap: 20px;
  align-items: center;
}
.wrapper .title {
  font-size: 18px;
}
.wrapper img {
  height: 40px;
}

